(function () {
  'use strict';

  angular
    .module('pp_analytics.pages', [
      // 'pp_analytics.guess_pages',
      // 'pp_analytics.integrationPages',
      // 'pp_analytics.short_links',
      // 'pp_analytics.pages.commonActions',
      // 'pp_analytics.app_states',
      // 'ppCommon.choosenMerchant'
    ])
    .config(config);

  config.$inject = ['$stateProvider', '$urlRouterProvider'];


  //<editor-fold desc="State resolvers, must pass these in order to enter a route">
  // NOTE: Must pass all resolver of a parent state in order to enter a child state

  // Search - Sort - Pagination resolver

  // CAUTION
  // ui-route resolver does not throw any error exception to console
  // in any case like: inject wrong service name, variable is not defined
  // It's hard in development
  function config($stateProvider, $urlRouterProvider) {
    // var APP_STATES = appStateConstants;

    // Merge search-sort-pagination and permission resolver
    // var searchSortPaginationAndPermissionResolver = _.merge({}, appRouteHelperProvider.getResolver('searchSortPaginationResolvers'), appRouteHelperProvider.getResolver('permissionResolver'));
    //</editor-fold>

    // Other than declared routes, go to public page
    $urlRouterProvider.otherwise('/');


    // IMPORTANT - sort defined-states based on its priority
    ($urlRouterProvider._router || $urlRouterProvider.router).urlRouter.sort(function(a,b) {
      return _.get(b, 'state.priority', 0) - _.get(a, 'state.priority', 0);
    });

  }
})();

(function () {
  'use strict';

  angular.module('pp_analytics.modules', [
    // 'ppExtensions.textAngular.imageUploader',

    'pp-angular-ui-switch',
    // 'pp_analytics.modules.ppFormly',
    // 'pp_analytics.modules.parcelAdditionalDetails',
    // 'pp_analytics.modules.publicPage.studie',
    // 'pp_analytics.modules.publicPage.checklist',
    // 'pp_analytics.modules.uploadParcels',
    // 'pp_analytics.trackingParcels'
  ]);
})();

(function () {
  const controller = [
    '$scope',
    function ($scope) {
      const vm = this;

      $scope.defaultText = $scope.defaultText || 'Copy';
      $scope.copiedText = $scope.copiedText || 'Copied!';
      $scope.btnBasedClasses = $scope.btnBasedClasses || 'btn';
      $scope.btnDefaultClass = $scope.btnDefaultClass || 'pp-btn btn-primary';
      $scope.ngDisabled = $scope.ngDisabled || false;

      vm.copied = false;

      vm.onCopied = function () {
        // if (isDisabled()) {return console.log("ppCopyToClipboardBtn disabled");}
        vm.copied = true;
      };

      vm.onMouseLeaveBtn = function () {
        vm.copied = false;
      };

      // function isDisabled() {
      //   if(_.isFunction($scope.ngDisabled)) {
      //     return $scope.ngDisabled();
      //   }

      //   return $scope.ngDisabled;
      // }
    },
  ];

  /**
   * This module require angular-clipboard
   */
  angular
    .module('pp_analytics.modules')
    .directive('ppCopyToClipboardBtn', function () {
      return {
        restrict: 'EA',
        template: require('appBasePath/app/shared/directives/pp-copy-to-clipboard-btn/pp-copy-to-clipboard-btn.html'),
        scope: {
          btnBasedClasses: '@?',
          btnDefaultClass: '@',
          btnCopiedClass: '@',

          defaultText: '@?', // Button default text
          copiedText: '@?', // Button after copy text

          copyContent: '@?', // Content in text area
          ngDisabled: '=?',
        },
        controller,
        controllerAs: 'vm',
      };
    });
})();

(function () {
  'use strict';

  angular
    .module( 'pp_analytics.modules' )
    .directive( 'ppClickToEditTextField',
      function () {
        return {
          restrict: 'E',
          template: require('appBasePath/app/shared/directives/pp-click-to-edit-text-field/pp-click-to-edit-text-field.html'),
          scope: {
            model: "=",
            textAlign: '@?',
            emptyMessage: '@?'
          },
          controllerAs: 'vm',
          bindToController: true,
          controller: [
            '$timeout',
            function ($timeout) {
              var vm = this;
              var savedValue; // Backup value of model when user edit then cancel
  
              vm.model = vm.model || '';
              vm.inputElementId = Math.random().toString() + Date.now().toString();
              vm.isEditMode = false;
              vm.TEXT_ALIGN_CENTER = 'center';
  
              vm.enterEditMode = function() {
                vm.isEditMode = true;
                savedValue = vm.model;
  
                $timeout(function(){
                  document.getElementById(vm.inputElementId).focus();
                }, 50);
              };
  
              vm.cancel = function() {
                vm.model = savedValue;
                vm.isEditMode = false;
              };
  
              vm.save = function() {
                // Call API service
                // Then success (store new value to model)
                savedValue = vm.model;
                vm.isEditMode = false;
              };
            }
          ]
        }
      } );
})();

(function () {
  'use strict';

  angular.module('pp_analytics.services', [])
})();

(function () {
  'use strict';

  angular
    .module('pp_analytics.services')
    .factory('ppLocationService', [ '$location',
      ppLocationService
    ]);

  function ppLocationService ($location) {
    var watchFunction = [];

    return {
      onChangeLocation: onChangeLocation,
      removeWatch: removeWatch,
      readSearchParams: readSearchParams
    }

    function onChangeLocation (onChange) {
      watchFunction.push(onChange);
      return _.partial(removeWatch, onChange);
    }

    function removeWatch (onChange) {
      _.pull(watchFunction, onChange);
    }

    function readSearchParams () {
      return $location.search();
    }
  }
})();

(function () {
  'use strict';

  angular
    .module('pp_analytics', [
      'pp_shared',
      // App modules
      // 'pp_analytics.app_states', // 'es6-modules', 'pp_analytics.pages' dependency
      'es6-modules', // includes 'ppArray' (ui-router params type) that 'pp_analytics.pages' used.
      'pp_analytics.pages',
      'pp_analytics.modules',
      'pp_analytics.directives',
      'pp_analytics.filters',
      'pp_analytics.services',
      // 'pp_analytics.trackingPageEditor',
      // 'pp_analytics.configuredNotifications',
      // 'pp_analytics.trackingParcels',
      // 'pp_analytics.shared.components',

    ]);
})();

!(function () {
  'use strict';

  angular
    .module('pp_analytics')
    .config(['$httpProvider', function ($httpProvider) {
      $httpProvider.interceptors.push('noCacheInterceptor');
    }]).factory('noCacheInterceptor', function () {
    return {
      request: function (config) {
        if (config.method == 'GET' && !config.cache) {
          var separator = config.url.indexOf('?') === -1 ? '?' : '&';
          config.url = config.url + separator + 'noCache=' + new Date().getTime();
        }
        return config;
      }
    };
  });
})();

(function () {
  'use strict';


  // TODO - bich - remove - should use [carrierService.getMasterCarriers]
  angular
    .module('pp_analytics.services')
    .factory('masterDataService', ['$http', 'constants',
      function ($http, constants) {
        return {
          getCarrierMasterData: getCarrierMasterData
        };

        function getCarrierMasterData() {
          // TODO: use fetcher here
          return $http.get(constants.dataIoApis.carrierMasterData, {
            params: {
              quantity: 1000
            }
          });
        }
      }])
})();

// (function () {
//   'use strict';

//   var carrierCollection = null;


//   angular.module('pp_analytics.modules')
//     .service('appCommonDataUtils', ['$rootScope', 'appService', 'carrierService'
//       , 'ParcelCarrierCollection'
//       , utils]);


//   function utils($rootScope, appService, carrierService, ParcelCarrierCollection) {

//     carrierCollection = new ParcelCarrierCollection();


//     $rootScope.$watch(function(){
//       return appService.getChosenMerchantId();
//     }, function(){
//       carrierCollection.resetData();
//     });


//     return {
//       getCarrierCollection: getCarrierCollection
//     };


//     function getCarrierCollection() {

//       // Fullfil data by calling api:
//       // 1. Master data carriers
//       carrierService.getMasterCarriers(appService.getChosenMerchantId()).then(function (response) {
//         carrierCollection.masterCarriers = _.get(response, 'data.data');
//       });

//       return carrierCollection;
//     }
//   }
// }());

(function () {
  'use strict';

  angular.module('pp_analytics.filters', []);
})();
(function () {
  'use strict';

  angular
    .module('pp_analytics.filters')
    .filter('urlSafe', ['$sce', function ($sce) {
      return function (url) {
        return $sce.trustAsResourceUrl(url);
      }
    }])
})();

(function () {
  'use strict';

  angular
    .module('pp_analytics.filters')
    .filter('percentage', ['$filter', function ($filter) {
      return function (input, decimals) {
        if (!isNaN(input)) {
          input = input * 100;

          if (input % 1 == 0){ // integer, don't show digits
            return $filter('number')(input) + '%';
          } else { // decimal, show 1 digit
            return $filter('number')(input, decimals) + '%';
          }
        }
      }
    }]);
})();
(function () {
  'use strict';

  angular
    .module('pp_analytics.filters')
    .filter('maxWord', function () {
      return function (value, wordwise, max, tail) {
        if (!value) return '';

        max = parseInt(max, 10);
        if (!max) return value;
        if (value.length <= max) return value;

        value = value.substr(0, max);
        if (wordwise) {
          var lastspace = value.lastIndexOf(' ');
          if (lastspace != -1) {
            //Also remove . and , so its gives a cleaner result.
            if (value.charAt(lastspace - 1) == '.' || value.charAt(lastspace - 1) == ',') {
              lastspace = lastspace - 1;
            }
            value = value.substr(0, lastspace);
          }
        }

        return value + (tail || ' …');
      };
    })
})();

(function () {
  'use strict';

  angular
    .module('pp_analytics.filters')
    .filter('htmlSafe', ['$sce', function ($sce) {
      return function (htmlCode) {
        return $sce.trustAsHtml(htmlCode);
      }
    }])
})();

(function () {
  'use strict';

  angular
    .module('pp_analytics.filters')
    .filter('capitalize', function () {
      return function (input) {
        return (!!input) ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : '';
      }
    });
})();
// (function () {
//   'use strict';
//
//   angular
//     .module('ppUiSelectExtensions', [])
//     .directive('uiSelectOnBlur', function () {
//       return {
//         restrict:'A',
//         require: 'uiSelect',
//         link: function (scope, elm, attrs, ctrl) {
//           var searchInputEle = ctrl.searchInput;
//           if (searchInputEle) {
//             searchInputEle.on('blur', function() {
//               scope.$eval(attrs.uiSelectOnBlur);
//             })
//           }
//         }
//       }
//     })
// })();

(function() {
  'use strict';

  angular.module('pp_analytics.directives', []);
})();

(function () {
  'use strict';

  angular
    .module('pp_analytics.directives')
    .component('ppNoDataChartOverlay', {
      restrict: 'E',
      template: require('shared/directives/no-data-chart-overlay.html'),
      bindings: {
        smallVersion: '='
      },
      controllerAs: 'vm',
      controller: ppNoDataChartOverlayCtrl
    });

  function ppNoDataChartOverlayCtrl() {
    // var vm = this;
  }
})();

(function () {
  'use strict';

  angular
    .module('pp_analytics')
    .config(['$compileProvider', function ($compileProvider) {
      $compileProvider.preAssignBindingsEnabled(true);
    }])
    .config(['$locationProvider', '$breadcrumbProvider', '$compileProvider',
      function ($locationProvider, $breadcrumbProvider, $compileProvider) {

        // Disable debug classes (ng-bind, ng-scope, ...)
        $compileProvider.debugInfoEnabled(false);

        // Disable hashbang mode (read more: https://docs.angularjs.org/guide/$location)
        $locationProvider.html5Mode({
          enabled: true,
          requireBase: false
        });

        // Include abstract state 'main' in breadcrumb
        $breadcrumbProvider.setOptions({
          includeAbstract: true
        });
      }])
    .config(['$uibTooltipProvider', function($uibTooltipProvider) {
      $uibTooltipProvider.options({
        appendToBody: true
      });
    }])

    .config(['$stateProvider', function($stateProvider) {
      _.invoke($stateProvider, 'stateService.defaultErrorHandler', function(err){
      });
    }])
    .run(['$state', '$rootScope',
      'validator',   
      'constants', '$window', 'ppPermissionService', 'appStateManager',
      function ($state, $rootScope, validator, constants , $window, ppPermissionService, appStateManager
      ) {

      var permissionService = ppPermissionService;
        $rootScope.PUBLIC_ASSETS_PATH = 'https://assets.parcelperform.com/pp-web-app-assets';

        $rootScope.APP_STATES = appStateManager
        $rootScope.APP_STATES = appStateManager

        $rootScope.$state = $state;
        $rootScope.CONSTANTS = constants;                 // inject constants to $rootScope - using in template - [$root.CONSTANTS]
        $rootScope.permissionService = permissionService;

        //Inject common helpers to rootScope
        window.injectUtils && window.injectUtils($rootScope);
        $rootScope.goToPage = function(pageName) {
          $state.go(_.get(constants.states, pageName, pageName));
        };

        AOS.init({
          once: true,
          duration: 600
        });

        $rootScope.$on('$stateChangeError', function() {
          var transition = arguments[arguments.length -1];

          if (_.get(transition, '_options.custom.ppIgnoreTransitionError')) {
            return;
          }

          return $state.go( $rootScope.APP_STATES.getStateName('PUBLIC_PAGE__LANDING_PAGE') );
        });


        addValidationCommonRules();


        function addValidationCommonRules() {
          validator.enable(false);
        }
      }]);
})();
